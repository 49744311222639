.form-container {
    width: 80%;
    margin: auto;
}

.grid-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
}

.form-item {
    display: grid;
    grid-template-rows: repeat(2, auto);
}

.two-together {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
}

/* Form item */

.form-item label {
    color: var(--primary-color-light);
    font-weight: 500;
    padding: 3px;
    /* padding-left: 0px; */
    cursor: pointer;
}

.form-item .input {
    background-color: #fff;
    border: 2px solid #E3E3E5;
    border-radius: 8px;
    height: 45px;
    padding: 0 10px 0 10px;
    font-size: 16px;
    transition: var(--tran-03);
}

.form-item .input:focus {
    outline: none;
    border: 2px solid rgba(47, 141, 213, 0.834);
    box-shadow: 1px 0px 20px rgba(5, 1, 45, 0.4);
}

/* Button */

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.button-container .btn {
    background-color: var(--primary-color);
    color: white;
    /* border: 3px solid var(--primary-color); */
    padding: 3px;
    border-radius: 5px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    width: 221px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
    text-decoration: none;
    font-size: 0.98em;
    margin-bottom: 50px;
}

.button-container .btn:hover {
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
    transition: all 0.5s ease;
}

.button-container .btn::after {
    content: "";
    background-color: #ee7355;
    position: absolute;
    z-index: -1;
    padding: 16px 20px;
    display: block;
    left: -20%;
    right: -20%;
    top: 0;
    bottom: 0;
    transform: skewX(-45deg) scale(0, 1);
    transition: all 0.5s ease;
}

.button-container .btn:hover::after {
    transition: all 0.5s ease-out;
    transform: skewX(-45deg) scale(1, 1);
}

/*==== MEDIA QUERIES ====*/

@media (max-width: 1200px) {

    .grid-form {
        grid-template-columns: 1fr;
    }

}
