.search-box {
    position: relative;
    transition: all 1s;
    width: 300px;
    height: 50px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 25px;
    border: 3px solid var(--sidebar-color);
    padding: 5px;
    margin-right: 10%;
}

.search-box .icon {
    position: absolute;
    box-sizing: border-box;
    padding: 10px;
    width: 20px;
    height: 20px;
    top: 2px;
    border-radius: 50%;
    color: var(--sidebar-color);
    font-size: 1.2em;
}

.search-box input {
    position: absolute;
    top: 0;
    left: 45px;
    width: 69%;
    height: 42.5px;
    line-height: 30px;
    outline: 0;
    border: 0;
    display: none;
    font-size: 1em;
    border-radius: 20px;
    padding: 0 10px;
    display: block;
    font-weight: 600;
    color: var(--primary-color-light);
}

.search-box input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
}

.search-box .clear-icon {
    position: absolute;
    box-sizing: border-box;
    padding: 10px;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 10px;
    color: var(--sidebar-color);
    font-size: 1.2em;
    cursor: pointer;
    transition: all 1s;
}

.search-box .clear-icon:hover {
    color: var(--primary-color);
}
