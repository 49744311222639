.add-box {
    background-color: var(--primary-color);
    color: white;
    border: 3px solid var(--primary-color);
    padding: 3px;
    border-radius: 25px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 221px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
    text-decoration: none;
}

.add-box .icon {
    font-size: 1.4em;
    margin-left: 12px;
}

.add-box .text {
    color: white;
    font-size: 0.98em;
    font-weight: 600;
    margin-left: -40px;
    margin-right: -40px;
    display: flex;
    align-items: center;
}

.add-box:hover {
    color: #fff;
    border: 3px solid var(--sidebar-color);
    transition: all 0.5s ease;
}

.add-box:hover .text {
    color: white
}

.add-box::after {
    content: "";
    background-color: #ee7355;
    position: absolute;
    z-index: -1;
    padding: 16px 20px;
    display: block;
    left: -20%;
    right: -20%;
    top: 0;
    bottom: 0;
    transform: skewX(-45deg) scale(0, 1);
    transition: all 0.5s ease;
}

.add-box:hover::after {
    transition: all 0.5s ease-out;
    transform: skewX(-45deg) scale(1, 1);
}
