.formbox{
    margin-top: -25px;
    display:flex;
    justify-content: space-evenly;
}
.formbox lavel{
    margin-right: 10px;
}
.button-container{
    margin-top: 50px;
}
.formitem{
    display: flex;
    gap: 8px;
    align-items: center;
    width: 300px;
}

/* styles.css */
.radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Optional: adds space between each radio button */
}

.radio-group label {
    display: flex;
    align-items: center;
}
input {
    margin: 0.4rem;
  }