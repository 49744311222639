.pagination-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    width: 100%;
}

.pagination-container .pagination-button {
    border: none;
    background: none;
    font-size: 18px;
    color: var(--navbar-color);
    cursor: pointer;
    margin: 0 5px;
    width: 25px;
    height: 28px;
    transition: all 0.4s ease;
    border-radius: 50%;
}

.pagination-container .page-number {
    margin: 0 10px;
    padding: 5px;
    background-color: var(--navbar-color);
    color: #fff;
    width: 60px;
    text-align: center;
    font-size: 16px;
}

.pagination-container .pagination-button:hover {
    color: white;
    background-color: var(--navbar-color);
}

/* Disabled buttons */
.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-button:disabled:hover {
    color: var(--navbar-color);
    background-color: transparent;
}

.pagination-button:disabled .icon {
    cursor: not-allowed;
    background: none;
}
