.formbox{
    margin-top: -25px;
    display:flex;
    justify-content: space-evenly;
}
.formbox lavel{
    margin-right: 10px;
}
.button-container{
    margin-top: 50px;
}
.formitem{
    display: flex;
    gap: 20px;
    align-items: center;
    width: 350px;
}