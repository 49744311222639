.home-container {
    width: 100%;
    height: 100%;
}

/* .panel:nth-child(4) {
    display: block;
} */

.dashboard {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
    width: 90%;
    height: 80%;
    margin: auto;
}

.dashboard .row-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.dashboard .row-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

/* ===== PANEL =====*/

.dashboard .panel {
    background-color: white;
    box-shadow: 5px 5px 10px 1px rgba(41, 39, 40, 0.1);
    border: 1px solid rgba(41, 39, 40, 0.1);
    border-radius: 20px;
}


/*==== PANEL HEADING ====*/

.dashboard .panel .panel-heading {
    padding: 11px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    color: #333333;
    background-color: #f1f2f2;
    border-color: rgba(41, 39, 40, 0.1);
    height: 23%;
}

.dashboard .panel .panel-heading .panel-title {
    display: flex;
    align-items: center;
    height: 100%;
}

.dashboard .panel .panel-heading .panel-title-icon {
    background: white;
    border-radius: 100%;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 13px;
    padding: 10px;
    text-align: center;
}

.dashboard .panel .panel-heading .panel-title span {
    font-weight: 500;
}


/*==== PANEL BODY ====*/

.dashboard .panel .panel-body {
    height: 50%;
    padding: 0 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.dashboard .item-4 .panel-body,
.dashboard .item-5 .panel-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 20px; */
}

.dashboard .panel .panel-body h4 {
    font-weight: 500;
}


/*==== PANEL FOOTER ====*/

.dashboard .panel .panel-footer {
    padding: 0px 5px;
    display: flex;
    justify-content: center;
    height: 27%;
    align-items: center;
}

.dashboard .panel .panel-footer .btn {
    background-color: var(--primary-color);
    color: white;
    /* border: 3px solid var(--primary-color); */
    padding: 3px;
    border-radius: 5px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
    text-decoration: none;
    font-size: 0.90em;
}

.dashboard .item-1 .panel-footer .btn {
    width: 135px;
    margin-right: 1%;
}

.dashboard .item-1 .panel-footer .btn:last-child {
    margin-right: 0;
}

.dashboard .panel .panel-footer .btn:hover {
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
    transition: all 0.5s ease;
}

.dashboard .panel .panel-footer .btn::after {
    content: "";
    background-color: #ee7355;
    position: absolute;
    z-index: -1;
    padding: 16px 20px;
    display: block;
    left: -20%;
    right: -20%;
    top: 0;
    bottom: 0;
    transform: skewX(-45deg) scale(0, 1);
    transition: all 0.5s ease;
}

.dashboard .panel .panel-footer .btn:hover::after {
    transition: all 0.5s ease-out;
    transform: skewX(-45deg) scale(1, 1);
}


/*==== MEDIA QUERIES ====*/

@media (max-width: 1700px) {

    .dashboard .item-1 .panel-body {
        flex-direction: column;
    }
}

@media (max-width: 1100px) {

    .dashboard {
        display: block;
    }

    .dashboard .row-1 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        /* width: 90%; */
        height: 80%;
    }

    .dashboard .row-1 .item-3 {
        grid-column: 1 / 3;
    }

    .dashboard .row-2 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        height: 80%;
        margin-top: 20px;
    }
    
}
