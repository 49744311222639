/* Item selection */

.item-selection-container .top-articles {
    width: 50%;
    margin: auto;
    margin-top: 20px;
}

.item-selection-container .top-articles label {
    color: var(--primary-color-light);
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
}

.item-selection-container .top-articles .options {
    width: 50%;
    margin: auto;
    margin-top: 20px;
}

.item-selection-container .table-container {
    top: 20px;
}

.item-selection-container .table-container .pagination-container {
    margin-top: 40px;
}


/* Sale */

.item-selection-container .top-sale {
    width: 50%;
    margin: auto;
    margin-top: 70px;
}

.item-selection-container .top-sale hr {
    margin-bottom: 30px;
}

.item-selection-container .top-sale label {
    color: var(--primary-color-light);
    font-size: 30px;
    font-weight: 700;
    padding: 10px;
    margin-left: 100px;
    cursor: pointer;
}

.item-selection-container .input {
    background-color: #fff;
    border: 2px solid #E3E3E5;
    border-radius: 8px;
    height: 35px;
    width: 80px;
    padding: 0 10px 0 10px;
    font-size: 16px;
    transition: var(--tran-03);
}

.item-selection-container .input:focus {
    outline: none;
    border: 2px solid var(--navbar-color);
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
}

.item-selection-container .total {
    font-weight: 500;
}

/* ============= CHECKBOX ============= */

/* Customize the label (the container) */
.checkbox-container {
    /* display: block; */
    text-align: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: default;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.checkbox-container input:disabled ~ .checkmark {
    cursor: not-allowed;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
    background-color: #ccc;
}

.checkbox-container:hover input:disabled~.checkmark {
    background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
    /* background-color: #2196F3; */
    background-color: var(--primary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* itemselection.css */

.form-group {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 4px;
}

.form-group .form-control {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
  
}
.summaryHeader {
    display: flex;
    justify-content: space-evenly;
}

.form-group .form-control:focus {
    border-color: #4CAF50;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 128, 0, 0.3);
}

.table-container {
    margin-top: 15px;
}

.table-minimal {
    border-collapse: collapse; /* Removes space between cells */
    width: 100%; /* Optional: adjust table width */
}

.table-minimal th, 
.table-minimal td {
    padding: 4px; /* Minimal padding */
    font-size: 13px; /* Small font size */
    text-align: center; /* Align text to the left */
    border: 1px solid #d5d2d2; /* Optional: add border for clarity */
}

.table-minimal th {
    background-color: #1161b0da; /* Optional: header background */
    color: #ffffff; 
    
}