.newSale-container .form-container {
    width: 100%;
    margin: auto;
}

.newSale-container .grid-form {
    margin-top: 0px;
    grid-template-columns: 0.5fr;
    justify-content: center;
}

/* ===== Button ===== */

.newSale-container .button-container {
    margin-top: 70px;
}
.sales-icon{
    color: #008000 ;
}