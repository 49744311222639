.detailPurchase-container .top-purchase {
    width: 100%;
    margin: auto;
    margin-top: 0px;
    text-align: center;
    
}
#root > div > div.main-content > div > div.top-purchase > table{
    width: 100%;
    text-align: start;
    margin-left: 152px;

}
#root > div > div.main-content > div > div.top-purchase > table> thead{
font-weight: 500;
font-size: 20px;
color: red;
}


.detailPurchase-container .top-purchase h2 {
    color: var(--primary-color-light);
    font-weight: 500;
    font-size: 20px;
    /* padding: 10px; */
}

.detailPurchase-container .top-purchase p {
    color: var(--primary-color-light);
    padding: 20px;
    margin-bottom: 20px;
}

.detailPurchase-container .table-container {
    top: 20px;
}

.detailPurchase-container .total {
    font-weight: 500;
}
