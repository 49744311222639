.detailSale-container .top-sale {
    width: 50%;
    margin: auto;
    margin-top: 0px;
    text-align: center;
}

.detailSale-container .top-sale h2 {
    color: var(--primary-color-light);
    font-weight: 500;
    font-size: 20px;
    /* padding: 10px; */
}
#root > div > div.main-content > div > div.top-sale > table{
    width: 234%;
    text-align: start;
    margin-left: -143px;
}
#root > div > div.main-content > div > div.top-sale > table >thead{
font-weight: 500;
font-size: 20px;
color: red;
}
.detailSale-container .top-sale p {
    color: var(--primary-color-light);
    padding: 20px;
    margin-bottom: 20px;
}

.detailSale-container .table-container {
    top: 20px;
}

.detailSale-container .total {
    font-weight: 500;
}
