.confirmation-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.confirmation-popup-content {
    background: #ffffff;
    height: 150px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.confirmation-popup button {

    margin: 40px 15px 10px 0px ;
    width: 130px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.2) ;
    border-radius: 30px;
}
.trash-icon{
    color: red;
    cursor: pointer;
}
.styled-table>thead{
    background-color: orange;
}
.yes-btn{
   
}
