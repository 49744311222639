.new-payment-container .form-container {
    width: 100%;
    margin: auto;
}

.new-payment-container .grid-form {
    margin-top: 0px;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
}
.grid-form {
    margin: 0px 40px;
}

/* ===== Button ===== */

.new-payment-container .button-container {
    margin-top: 70px;
}
input, select{
    background-color: #fff;
    border: 2px solid #E3E3E5;
    border-radius: 8px;
    height: 45px;
    padding: 0 10px 0 10px;
    font-size: 16px;
    transition: var(--tran-03);
}
SearchSelect{
    border: 1px black solid;
    border-radius: 10px;
}
.icon{
    margin: 7px;
}
