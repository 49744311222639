/* ===== Sidebar ===== */
 .sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 280px;
    padding: 5px 10px;
    background: var(--sidebar-color);
    transition: var(--tran-03);
}

.sidebar-container.close {
    width: 88px;
}

/* ===== Reusable code - Here ===== */
.sidebar-container li {
    height: 50px;
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.sidebar-container header .image,
.sidebar-container .icon {
    min-width: 60px;
    border-radius: 6px;
}

.sidebar-container .icon {
    min-width: 60px;
    border-radius: 6px;
    height: 43%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.sidebar-container .text,
.sidebar-container .icon {
    color: var(--text-color);
    transition: var(--tran-03);
}

.sidebar-container .text {
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    opacity: 1;
}
.sidebar-container.close .text {
    opacity: 0;
}
/* =========================== */

.sidebar-container header {
    position: relative;
}

.sidebar-container header .image-text {
    display: flex;
    align-items: center;
}
.sidebar-container header .logo-text {
    display: flex;
    flex-direction: column;
}
header .image-text .name {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 600;
}

header .image-text .profession {
    font-size: 16px;
    margin-top: -2px;
    display: block;
}

.sidebar-container header .image{
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-container header .image img {
    width: 40px;
    border-radius: 6px;
}

.sidebar-container header .toggle {
    position: absolute;
    top: 50%;
    right: -25px;
    transform: translateY(-50%) rotate(180deg);
    height: 25px;
    width: 25px;
    background-color: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    color: var(--text-color);
    transition: var(--tran-03);
}

.sidebar-container.close .toggle {
    transform: translateY(-50%) rotate(0deg);
}

.sidebar-container .menu{
    margin-top: 40px;
}

.sidebar-container li a {
    list-style: none;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    text-decoration: none;
    transition: var(--tran-03);
}

.sidebar-container li a:hover {
    background-color: var(--primary-color);
}
.sidebar-container li a:hover .icon,
.sidebar-container li a:hover .text {
    color: var(--sidebar-color);
}
.sidebar-container li a:hover .icon,
.sidebar-container li a:hover .text {
    color: var(--text-color);
}

.sidebar-container .menu-bar {
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}
.sidebar-container .menu-bar::-webkit-scrollbar {
    display: none;
}

.sidebar-container li.selected:not(:hover)::before {
    content: "";
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    margin-right: -4px;
    background-color: var(--primary-color);
    transition: var(--tran-03);
    color: var(--primary-color);
}

.sidebar-container li.selected:not(:hover) .icon,
.sidebar-container li.selected:not(:hover) .text {
    color: var(--secondary-color);
}

.sidebar-container li.selected:not(:hover) a {
    background-color: #31363b; /* #2B3035 */
    z-index: -1;
}
