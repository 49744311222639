/* ReportsPage.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

  }
  
  .button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  .reports-container>h1{
    font-size: 30px;
    font-weight: 500;
    color: var(--primary-color-light);
    padding: 12px 60px;
    height: 100px;
    display: flex;
    justify-content: center;

  }
  
    .formbox{
      margin-top: -25px;
      display:flex;
      justify-content: space-evenly;
      margin-left: 40px;
      align-items: center;
    }
  .formbox lavel{
      margin-right: 10px;
  }
  .button-container{
      margin-top: 50px;
  }
  .formitem{
      display: flex;
      gap: 9px;
      align-items: center;
      width: 350px;
  }
  .buttons{
    margin-Left:0px !important;
  }
.spcl{
margin-right: 50px;
}
.report-head{
  text-align: center;
  font-size: 30px;
}