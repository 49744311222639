.light-blue-row {
    background-color: lightblue;
    color: black;
}
.main-content .tabled-container {
    position: relative;
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    top: 0px;
    width: 80%;
    margin: auto;
    /*background-color: #000000;*/
}
.download-container {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: flex-end; /* Aligns items to the right */
    gap: 10px; /* Optional spacing between icon and button */
}

.download-icon {
    width: 24px; /* Set desired width */
    height: 24px; /* Set desired height */
}

.download-button {
    /* Add any additional styles for the button */
    padding: 10px 15px;
    border: none;
    background-color: #007bff; /* Example background color */
    color: white; /* Text color */
    cursor: pointer;
    border-radius: 4px; /* Rounded corners */
}

.download-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}


