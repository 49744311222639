/* Styling the main table */
.styled-table {
    border-collapse: collapse;
    margin: 25px 100px;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 80%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Styling the table header */
.styled-table thead tr {
    background-color:#009879;;
    color: #ffffff;
    text-align: center;
}
.styled-table tbody tr {
    text-align: center;

}

/* Styling the table cells */
.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

/* Styling the table rows */
.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

/* Styling the active row */
.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}
