.confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it's above other content */
}

.confirmation-popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.confirmation-popup-content p {
    margin-bottom: 20px;
}

.yes-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.yes-btn:hover {
    background-color: #218838;
}

.confirmation-popup-content button {
    background-color: #ff4052;
    color: white;
    border: none;
    margin: 10PX 5PX;
    padding: 2px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.confirmation-popup-content button:hover {
    background-color: #c82333;
}
